import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
// import {HTMLContent} from '../components/Content'
// import backgroundImage from '../../static/img/background-2.jpg'
// import persoon1 from '../../static/img/team/LiesbethVanlommel.jpg'
// import persoon2 from '../../static/img/team/KatrienBartholomeeusen.jpg'
// import persoon3 from '../../static/img/team/LoesKenis.jpg'
// import persoon4 from '../../static/img/team/JoeriVercauteren.jpg'
// import persoon5 from '../../static/img/team/LiesbethAndries.jpg'
// import persoon6 from '../../static/img/team/CharlotteHuybrechts.jpg'
import PageHeader from '../components/PageHeader'
import TeamLid from '../components/TeamLid'
import {getUrlFromImage} from "../util/CommonUtil";

// const teamleden2 = [
//     {
//         image: persoon1,
//         naam: 'Liesbeth Vanlommel',
//         functie: 'Zaakvoerder',
//         opleiding: 'Master in de Revalidatiewetenschappen en  Kinesitherapie, postgraduaat manuele therapie en Bachelor orthopedie: schoentechnologie voor sport en revalidatie',
//         interesse: 'Sportkinesitherapie, pre- en postoperatief, loopanalyse, sportscreening',
//         quote: 'Gepassioneerd door sport wil ik steeds blijven bijleren over hoe ons lichaam beweegt. Als ex-meerkampster weet ik hoe moeilijk het is om bij iets wat je heel graag doet geblesseerd aan de kant te staan. Hierdoor werk ik graag op een actieve manier met patiënten om hun zo snel als mogelijk opnieuw te laten doen wat ze graag doen.'
//     },
//     {
//         image: persoon2,
//         naam: 'Katrien Bartholomeeusen',
//         functie: 'Kinesitherapeut & coach',
//         opleiding: 'Master in de revalidatiewetenschappen en kinesitherapie, master in de manuele therapie, master in de sportkinesitherapie, ICF coach',
//         interesse: '- Manuele Therapie : acute, complexe en terugkerende klachten van rug, nek, bekken en daarbijhorende andere klachten beelden. \n' +
//             '- Coaching, consulting en communicatie : professionele en persoonlijke ontwikkeling als individu, in relatie tot zichzelf, het werk en andere mensen. Hierbij richt ik mij op iedereen die graag inzicht wil krijgen in de huidige situatie en op zoek wil gaan naar mogelijkheden om eruit te geraken. (www.co-consult.be)',
//         quote: 'Luisterend naar jou als patiënt of klant ga ik op zoek naar de dominante factoren in jouw verhaal en klachtenbeeld. \n' +
//             'Grondigheid, kwaliteit, duidelijkheid en oplossingsgericht werken en ondertussen meer dan 20 jaar ervaring zet ik in om jou vooruit te helpen.\n'
//     },
//     {
//         image: '',
//         naam: 'Ben Vanthienen',
//         functie: 'Kinesitherapeut',
//         opleiding: 'Master in de Revalidatiewetenschappen en  Kinesitherapie en postgraduaat manuele therapie',
//         interesse: 'Bewegingsanalyse / detectie van mechanismes die leiden tot letsels',
//         quote: 'Mijn passie voor de mens en zijn bewegen drijven mijn jobvreugde in de kinesitherapie.\n' +
//             'Patiënten behandelen én lesgeven aan de universiteit is voor mij de ideale combinatie.\n' +
//             'Sportieveling of actieveling, ik ga steeds voor een persoonlijke, motiverende aanpak in samenwerking mét de patiënt.'
//     },
//     {
//         image: persoon3,
//         naam: 'Loes Kenis',
//         functie: 'Kinesitherapeut',
//         opleiding: 'Master in de Revalidatiewetenschappen en  Kinesitherapie en postgraduaat manuele therapie',
//         interesse: 'Kaakklachten (TMG), bekkenpijn en zwangerschapskinesitherapie, hoofdpijnklachten, pre- en postoperatief, Move More, Move Pilates',
//         quote: 'Omdat ons lichaam nu eenmaal gemaakt is om te bewegen, besteed ik zelf met \n' +
//             'plezier veel tijd aan bewegen en ontdekken. Ik hou mijn conditie op peil door te lopen, fietsen, volleyballen en pilates. Net zo graag zet ik ook een stapje in de wereld om nieuwe oorden te ontdekken. Lekkere eetplekjes, concerten, films en boeken staan permanent op mijn verlanglijstje. \n' +
//             'Met veel goesting en motivatie werk ik al enkele jaren bij Beweegpunt in een sterk en leergierig team. Tot uw dienst!\n'
//     },
//     {
//         image: persoon4,
//         naam: 'Joeri Vercauteren',
//         functie: 'Kinesitherapeut',
//         opleiding: 'Master in de Revalidatiewetenschappen en  Kinesitherapie en postgraduaat manuele therapie',
//         interesse: 'Sportletsels, nekproblemen, loop-, fiets- en sportanalyses',
//         quote: 'Naast koffie en muziek behoren sport en beweging tot mijn grootste passies. Ik ben ervan overtuigd dat beweging (correct en gedoseerd) van cruciaal belang is voor het welzijn van een gezond of herstellend lichaam. Ik ben kritisch ingesteld en altijd gebeten om op zoek te gaan naar de oorzaak van een lichamelijke klacht, eerder dan louter de symptomen te bestrijden. Om hiertoe te komen kijk ik naar de totale persoon in al zijn facetten, lichaam en geest zijn immers onlosmakelijk verbonden!\n' +
//             'Zelf ben ik gepassioneerd bezig met triathlon, zeilen, snowboarden, maar ook met concerten, theater en festivals.\n'
//     },
//     {
//         image: persoon6,
//         naam: 'Charlotte Huybrechts',
//         functie: 'Kinesitherapeut',
//         opleiding: 'Master in de Revalidatiewetenschappen en  Kinesitherapie en postgraduaat manuele therapie',
//         interesse: 'Zwangerschapskinesitherapie, bekkeninstabiliteit, BPPV, MoveMore, MoveYogalates, sportkinesitherapie',
//         quote: 'Ik ben kinesitherapeut geworden om mensen te helpen. Door middel van manuele therapie én oefeningen te doen ben ik zeer gemotiveerd om een verschil te kunnen maken. \n' +
//             'In mijn vrije tijd sport ik graag, ga ik graag naar concerten of festivals, uiteten of drinken. Mijn grootste passie echter is reizen.'
//     },
//     {
//         image: '',
//         naam: 'Joris Lemmens',
//         functie: 'Kinesitherapeut',
//         opleiding: 'Master in de Revalidatiewetenschappen en  Kinesitherapie en postgraduaat manuele therapie',
//         interesse: 'Rug- en nekschool, letsel-, pijn- en bewegingseducatie',
//         quote: 'Vanuit mijn voorliefde voor beweging en mensen te helpen heb ik me vol enthousiasme gesmeten in de kinesitherapie. Buiten het behandelen van aandoeningen aan gewrichten, spieren en pezen, hou ik me ontzettend graag bezig met het mechanisme “pijn”. Het is mijn missie om u inzicht te geven in uw ziektebeeld en genezingsproces. Informatie en advies vormen dan ook een belangrijk onderdeel van de behandeling.'
//     },
//     {
//         image: persoon5,
//         naam: 'Liesbeth Andries',
//         functie: 'Kinesitherapeut',
//         opleiding: 'Master in de Revalidatiewetenschappen en  Kinesitherapie',
//         interesse: 'BPPV, sportkinesitherapie, kaakgewricht, ademhaling (hyperventilatie), MoveMore ',
//         quote: 'Door jarenlang intensief aan atletiek te doen is mijn passie voor het lichaam en de beweging gegroeid. Ik besteed zelf nog steeds veel tijd aan bewegen. \n' +
//             'Samen met de patiënt op zoek gaan naar de oorzaak van de klacht en hier de gepaste behandeling voor vinden is mijn doel. Zo probeer ik ervoor te zorgen dat de patiënt zo snel mogelijk weer zijn activiteiten kan doen die hij graag doet.\n'
//     },
//
// ];
export const TeamPageTemplate = ({backgroundImage, titel, subTitel, motto, teamleden}) => {
    // console.log(teamleden)
    return (
        <div>
            <PageHeader imageUrl={getUrlFromImage(backgroundImage)}
                        titel={titel}
                        subTitel={subTitel}
                        motto={motto}/>
            <div className="container team-container">
                <section className="team">
                    {teamleden.map((teamlid, index) => (
                        <TeamLid key={index} teamlid={teamlid}/>
                    ))}
                </section>
            </div>
        </div>
    )
}

// TeamPageTemplate.propTypes = {
//     title: PropTypes.string.isRequired,
//     content: PropTypes.string,
//     contentComponent: PropTypes.func,
// }

const TeamPage = ({data}) => {
    const {markdownRemark: post} = data;
    return (
        <Layout>
            <TeamPageTemplate
                backgroundImage={post.frontmatter.backgroundImage}
                titel={post.frontmatter.title}
                subTitel={post.frontmatter.subtitle}
                motto={post.frontmatter.motto}
                teamleden={post.frontmatter.teamleden}
            />
        </Layout>
    )
}

TeamPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default TeamPage

export const teamPageQuery = graphql`
  query TeamPage {
    markdownRemark(frontmatter: { templateKey: { eq: "team-page" } }) {
      html
      frontmatter {
        backgroundImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
        motto
        teamleden {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
            }
            }
            naam
            functie
            opleiding
            interesse
            quote
          }
      }
    }
  }
`
