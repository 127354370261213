import React from "react";

const PageHeader = (props) => {
    // console.log(props)
    return (
        <section className="section-team has-text-centered" style={{background: `url(${props.imageUrl}) center center / cover no-repeat`,
            backgroundPosition: '50% -10px', filter: 'blur(0px)'
        }}>
            <div className="white-blur">
                <div className="container is-small has-text-centered">
                    <h1>{props.titel}</h1>
                    <h2>{props.subTitel}</h2>
                </div>
            </div>
            <div className="container is-small has-text-centered">
                <h3>{props.motto}</h3>
            </div>
        </section>
    );
};

export default PageHeader;
